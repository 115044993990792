<template>
    <div class="position-absolute position-relative text-secondary d-flex justify-content-end z-1" style="right: .25em; top: .2em" @focus="openMsgOptions(msg.idWpp, msg.timestamp)" @focusout="closeMsgOptions(msg.idWpp, msg.timestamp)" tabindex="-1">
        <b-icon icon="chevron-down" class="darkenTextOnHover" v-if="inMessageSupportedTypes(msg.type)" font-scale=".75" role="button"></b-icon>
        <div :id="`msgOptions-${msg.idWpp?.replace('==','').replace('.','') || Math.trunc(msg.timestamp)}`" class="position-absolute text-dark bg-silver shadow rounded flex-column w-max" :style="!msg?.fromMe ? 'right: -2.5em;' : ''" style="top: .75em; z-index:1; display: none">
            <span v-if="msg.type == 'chat' || msg.type == 'text'">
                <div class="d-flex align-items-center px-2 py-1 darkenOnHover" @click="setReplyMessage(msg)">
                    <b-icon icon="arrow90deg-left"></b-icon>
                    <div class="ms-2">
                        Responder
                    </div>
                </div>
                <div class="d-flex align-items-center px-2 py-1 darkenOnHover" @click="copyToClipboard(msg)">
                    <b-icon icon="files" flip-v flip-h></b-icon>
                    <div class="ms-2">
                        Copiar
                    </div>
                </div>
            </span>
            <div class="d-flex align-items-center px-2 py-1 darkenOnHover" @click="downloadMedia(msg.mediaURL)" v-if="msg.type == 'audio' || msg.type == 'ptt'">
                <b-icon icon="download"></b-icon>
                <div class="ms-2">
                    Download
                </div>
            </div>
            <div class="d-flex align-items-center px-2 py-1 darkenOnHover" @click="forwardMessage(msg)" v-if="fromAttendance && (client.channelType == 'business' || client.from)">
                <b-icon icon="arrow90deg-right"></b-icon>
                <div class="ms-2">
                    Encaminhar
                </div>
            </div>
            <!-- <div class="d-flex align-items-center px-2 py-1 darkenOnHover" @click="setEditMessage(msg)" v-if="fromAttendance && client.channelType == 'business' && msg.fromMe && (msg.type == 'chat' || msg.type == 'text') && isUnder15Minutes(msg.timestamp) && isFromCurrentOperator">
                <b-icon icon="pencil-square"></b-icon>
                <div class="ms-2">
                    Editar
                </div>
            </div>
            <div class="d-flex align-items-center px-2 py-1 darkenOnHover" @click="deleteMessage(msg)" v-if="fromAttendance && client.channelType == 'business' && msg.fromMe && isUnder1Day(msg.timestamp) && isFromCurrentOperator">
                <b-icon icon="trash"></b-icon>
                <div class="ms-2">
                    Apagar para todos
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import utils from '../utils/utils'

export default {
    props:[
        'msg', 'client', 'fromAttendance', 'channelIsBusiness', 'mediaURL',
        'isFromCurrentOperator'
    ],
    methods: {
        setReplyMessage(message) {
            this.$emit('setReplyMessageEvent',message)
        },
        copyToClipboard(message) {
            navigator.clipboard.writeText(message.body)
            this.closeMsgOptions(message.idWpp || message.timestamp)
        },
        inMessageSupportedTypes(type) {
            const types = [
                'image', 'video', 'ptt', 'audio', 'location', 'vcard', 'document', 'application',
                'sticker', 'list-reply', 'interactive', 'button_reply', 'text', 'chat'
            ]
            if(types.includes(type))
                return true
            return false
        },
        openMsgOptions(idWpp, timestamp) {
            if(timestamp)
                timestamp = Math.trunc(timestamp)

            if(idWpp)
                idWpp = idWpp.toString().replace('==','').replace('.','')

            let msgOptions = this.getElement(idWpp ? idWpp : timestamp)
            if(!msgOptions)
                msgOptions = this.getElement(timestamp)

            if(msgOptions)
                msgOptions.style.display = 'flex'
        },
        getElement(id) {
            if(id)
                return document.querySelector(`#msgOptions-${id}`)
        },
        closeMsgOptions(idWpp, timestamp) {
            if(timestamp)
                timestamp = Math.trunc(timestamp)

            if(idWpp)
                idWpp = idWpp.toString().replace('==','').replace('.','')

            let msgOptions = this.getElement(idWpp ? idWpp : timestamp)
            if(!msgOptions)
                msgOptions = this.getElement(timestamp)

            if(msgOptions)
                msgOptions.style.display = 'none'
        },
        forwardMessage(message) {
            this.$emit('forwardMessageEvent',message)
            this.closeMsgOptions(message.idWpp || message.timestamp)
        },
        deleteMessage(message) {
            this.$emit('deleteMessageEvent', {
                attendanceId: this.client._id, 
                channelId: this.client.channel || this.client.channelId,
                operatorId: this.client.operatorId,
                phone: this.client.clientNumber,
                key: {
                    remoteJid: `${this.client.clientNumber}@s.whatsapp.net`,
                    fromMe: message.fromMe,
                    id: message.idWpp,
                    // participant:
                }
            })
            this.closeMsgOptions(message.idWpp || message.timestamp)
        },
        setEditMessage(message) {
            this.$emit('setEditMessageEvent',message)
        },
        downloadMedia(url, ext = null) {
            url = this.hasFullPath(url)
            utils.downloadMedia(url, ext)
        },
        hasFullPath(url) {
            if(url && (url.startsWith('http') || url.startsWith('data:'))) return url
            else {
                const split = url.split('/')
                if(split?.at(2)?.length == 3) // verify if the position 2 (usually it is the channel id) has length of 3, then it means the media comes from Notifique
                    return this.mediaURL+url
            }
            return this.mediaURL+url
        },
        isUnder15Minutes(timestamp) {
            if (timestamp > 9999999999)
                timestamp = timestamp / 1000
            const now = Math.trunc(Date.now() / 1000)
            const diff = now - timestamp
            if(diff < 900)
                return true
            return false
        },
        isUnder1Day(timestamp) {
            if (timestamp > 9999999999)
                timestamp = timestamp / 1000
            const now = Math.trunc(Date.now() / 1000)
            const diff = now - timestamp
            if(diff < 86400)
                return true
            return false
        }
    }
}
</script>