<template>
    <div class="forwarded" v-if="msg.isForwarded">
        <b-icon icon="arrow-return-right" flip-v></b-icon>
        Encaminhada
    </div>
</template>

<script>
export default {
    props: [
        'msg'
    ]
}
</script>

<style scoped>
    .forwarded{
        color: #555;
    }
</style>