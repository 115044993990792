var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GmapMap',{staticStyle:{"width":"100%","height":"100px","margin":"10px 0"},attrs:{"center":{lat:_vm.lat, lng:_vm.lng},"zoom":7,"map-type-id":"terrain","options":{
zoomControl: false,
mapTypeControl: false,
scaleControl: false,
streetViewControl: false,
rotateControl: false,
fullscreenControl: false,
disableDefaultUi: false
}}})
}
var staticRenderFns = []

export { render, staticRenderFns }