<template>
    <div class="msgdttimediv" v-bind:class="{ 'msgdttimedivwhite': audio }">
        <div v-bind:class="{ 'hover': (item.fromMe && item.timeReceived) }">
            <div class="msgdttime">
                {{ formatTimestamp(item.timestamp) }}
                    <span v-if="item.fromMe">
                        <b-icon icon="clock" v-if="!item.statusAck"></b-icon>
                        <b-icon icon="headphones" scale="1.3" variant="primary" v-else-if="item.statusAck==4"></b-icon>
                        <b-icon icon="check-all" scale="1.3" variant="primary" v-else-if="item.statusAck==3"></b-icon>
                        <b-icon icon="check-all" scale="1.3" v-else-if="item.statusAck==2"></b-icon>
                        <b-icon icon="check" scale="1.3" v-else-if="item.statusAck==1"></b-icon>

                        <span class="hovercontent">
                            <div v-if="item.timeReceived">
                                <b-icon icon="check-all"></b-icon>
                                <span>{{ formatTimestamp(toTimestamp(item.timeReceived)) }}</span>
                            </div>
                            <div v-if="item.timeRead">
                                <b-icon icon="check-all" variant="primary"></b-icon>
                                <span>{{ formatTimestamp(toTimestamp(item.timeRead)) }}</span> 
                            </div>
                            <div v-if="item.timeListened">
                                <b-icon icon="headphones" variant="primary"></b-icon>
                                <span>{{ formatTimestamp(toTimestamp(item.timeListened)) }}</span> 
                            </div>
                        </span>
                    </span>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props:[
        'item'
    ],
    methods: {
        toTimestamp(date) {
            if(date) {
                const d = new Date(date)
                return d.getTime() / 1000
            }
            return ''
        },
        formatTimestamp(t){
            if(t) return moment.unix(new Date(parseInt(t))).format('HH:mm')
            return ''
        }
    },
    data(){
        return{
            dttime: moment.unix(new Date(this.item.timestamp)).format('HH:mm') || '',
            audio: (this.item?.type=='ptt' && this.item.url) || (this.item?.type == 'audio' && this.item.url)
        }
    }
}
</script>

<style scoped>
    .msgdttimediv{
        position: absolute;
        right: 5px;
        bottom: 2px;
    }
    @-moz-document url-prefix() {
        .msgdttimedivwhite{
            right: 0.5em !important;
        }
        .msgdttimedivwhite .msgdttime{
            color: #ccc !important;
        }
    }
    .msgdttime{
        font-size: 0.7em;
        color: #555;
    }
    .hover{
        position: relative;
    }
    .hover:hover .hovercontent{
        display: block;
    }
    .hovercontent{
        position: absolute;
        background-color: hsl(94deg 78% 87%);
        display: none;
        top: -25px;
        left: -10px;
        width: 50px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
</style>