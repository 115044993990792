<template>
    <div class="author" v-if="author">
        {{ author }}
    </div>
</template>

<script>
export default {
    props: [
        'msg'
    ],
    methods: {
        setAuthor() {
            if(this.msg.author)
                this.author = this.msg.author.replace('@c.us','')
            else if(this.msg.name && !this.msg.fromMe)
                this.author = this.msg.name
            // else if(this.msg.phone && !this.msg.fromMe)
            //     this.author = this.msg.phone
        }
    },
    mounted:
        function() {
            this.setAuthor()
        } 
    ,
    data() {
        return{
            author: '',
        }
    }
}
</script>

<style scoped>
    .author{
        color: #333;
        font-weight: 500;
    }
</style>