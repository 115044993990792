<template>
    <div class="position-relative">
        <!-- <div class="bg-success rounded-top p-3" v-if="contact">
            <div class="d-flex align-items-center">
                <b-avatar class="bg-secondary text-white" size="3rem" :src="verifyPhotoURL(contact.photoURL)"></b-avatar>
                <div class="text-white fw-bold ms-2">
                    {{ contact.clientName || contact.name }} 
                </div>
            </div>
        </div> -->
        <div class="waBg w-100" :class="contact ? 'rounded-bottom' : 'rounded'" :style="`height: ${height}`">
            <div class="text-purple d-flex align-items-center justify-content-center h-100" v-if="loading">
                <b-spinner class="shadow-sm" />
            </div>
            <div class="innerDiv" v-else>
                <b-button class="msgsScrollButton align-items-center justify-content-center rounded-circle border-0 bg-secondary p-1 position-absolute" @click="setScrollButton()" v-if="messages && messages.length"><b-icon font-scale="0.7" icon="chevron-double-down"></b-icon></b-button>
                <b-table head-variant="transparent" borderless class="tablemsgs tableW" id="tablemsgs" :fields="['body']" sort-by="timestamp" :items="msgs || messages">
                    <template #head()>
                        <div class="d-flex justify-content-center p-2" v-if="showLoadHistoryButton && user?.operatorData">
                            <div class="d-flex align-items-center" role="button" @click="loadProtocols()">
                                <b-icon class="text-white bg-success rounded-circle p-1 me-2" style="animation:spinner-border .75s linear infinite" font-scale="2" icon="arrow-repeat" v-if="loadingProtocol"></b-icon>
                                <b-icon class="text-white bg-success rounded-circle p-1 me-2" font-scale="2" icon="arrow-repeat" v-else></b-icon>
                                Carregar Histórico
                            </div>
                        </div>
                        <div class="d-flex justify-content-center p-2" v-else-if="showLoadMessagesButton">
                            <div class="d-flex align-items-center" role="button" @click="loadGroupPreviousMessages()">
                                <b-icon class="text-white bg-success rounded-circle p-1 me-2" style="animation:spinner-border .75s linear infinite" font-scale="2" icon="arrow-repeat" v-if="loadingPreviousGroupMessages"></b-icon>
                                <b-icon class="text-white bg-success rounded-circle p-1 me-2" font-scale="2" icon="arrow-repeat" v-else></b-icon>
                                Carregar Mensagens
                            </div>
                        </div>
                        <div class="d-flex justify-content-center p-2" v-else-if="showLoadMoreInternalMessagesButton">
                            <div class="d-flex align-items-center" role="button" @click="loadMoreInternalMessages()">
                                <b-icon class="text-white bg-success rounded-circle p-1 me-2" style="animation:spinner-border .75s linear infinite" font-scale="2" icon="arrow-repeat" v-if="loadingInternalMessages"></b-icon>
                                <b-icon class="text-white bg-success rounded-circle p-1 me-2" font-scale="2" icon="arrow-repeat" v-else></b-icon>
                                Carregar Mensagens
                            </div>
                        </div>
                        <div v-else></div>
                    </template>
                    <template #cell()="data">
                        <div class="rounded mx-5 p-2 my-2 text-white" style="background-color: rgba(0, 0, 0, 0.4)" v-if="data.item.type=='protocolStart' && data.item.createdAt">
                            <div class="font-weight-bold">Atendimento iniciado em: {{ formatDate(data.item.createdAt) }}</div>
                        </div>
                        <div class="text-white w-100 d-flex justify-content-center" v-else-if="data.item.type=='date'">
                            <div class="font-weight-bold w-fit rounded mx-5 p-2 my-2" style="background-color: rgba(0, 0, 0, 0.4)">{{ new Date(data.item.date) | date('DD/MM/YY') }}</div>
                        </div>
                        <div id="msgWrapper" class="w-100 h-100 position-relative d-flex" :class="{ 'justify-content-end': data.item.fromMe }" style="break-inside: avoid; page-break-inside: avoid;">
                            <span class="d-flex align-items-center" :class="{ 'mb-4': data.item.react?.length }">
                                <div class="reactButton d-none align-items-center h-100" :class="{ 'justify-content-end': data.item.fromMe, 'order-2': !data.item.fromMe }" v-if="(user && user.channel?.apiType != 'cloud') && inMessageSupportedTypes(data.item.type)">
                                    <b-icon class="bg-secondary text-white rounded-circle p-1 darkenOnHover" font-scale="1.5" icon="emoji-smile" role="button" @click="toggleDialogReactEmoji(data.item)"></b-icon>
                                </div>
                                <div id="inputReactEmojiPicker" ref="inputReactEmojiPicker" v-if="data.item.showReactEmojiDialog">
                                    <VEmojiPicker class="inputReactEmojiPicker" style="z-index: 1001" @select="selectReactEmoji" lang="pt-BR" :i18n="i18n" :style="msgsHeight ? `height: ${msgsHeight}` : 'height: 18vw'" />
                                </div>
                                <div class="w-100 h-100 position-fixed top-0 end-0 start-0 bottom-0" role="window" style="z-index: 1" @click="toggleDialogReactEmoji(data.item)" v-if="data.item.showReactEmojiDialog">
                                </div>
                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" v-if="data.item.type=='image'">
                                    <div class="p-1 pb-4" style="max-width: 75%" v-bind:class="{ 'msgreceived': !data.item.fromMe,'msgsent': data.item.fromMe }">
                                        <Msgfromgroup :msg="data.item"/>
                                        <Msgforwarded :msg="data.item" v-if="data.item.isForwarded"/>
                                        <img class="rounded" loading="lazy" v-bind:src="hasFullPath(data.item.mediaURL)" style="max-width:100%; height: auto;" @click="selectMedia(hasFullPath(data.item.mediaURL))" v-b-modal.modal-receivedmedia/>
                                        <a class="px-1" :href="hasFullPath(data.item.mediaURL)" v-if="pdfPrint">
                                            <!-- <b-icon class="me-2" icon="download"></b-icon> -->
                                            {{ hasFullPath(data.item.mediaURL) }}
                                        </a>
                                        <div class="px-2" style="white-space:break-spaces" v-if="data.item.caption">{{data.item.caption}}</div>
                                        <div class="px-2" style="white-space:break-spaces" v-else-if="data.item.body && notBase64(data.item.body)">{{data.item.body}}</div>
                                        <Msgdt :item="data.item"/>
                                    </div>
                                </div>
                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" v-else-if="data.item.type=='video'">
                                    <div :class="`p-1 pb-4 ${pdfPrint ? 'w-100' : 'w-50'} ${data.item.fromMe ? 'msgsent' : 'msgreceived'}`">
                                        <Msgfromgroup :msg="data.item"/>
                                        <Msgforwarded :msg="data.item" />
                                        <video class="rounded" controls style="max-width:100%" v-if="!pdfPrint">
                                            <source :src="hasFullPath(data.item.mediaURL)" type="video/mp4">
                                        </video>
                                        <a class="px-1" :href="hasFullPath(data.item.mediaURL)" v-else>
                                            <!-- <b-icon class="me-2" icon="download"></b-icon> -->
                                            Vídeo
                                        </a>
                                        <div class="px-2" style="white-space:break-spaces" v-if="data.item.caption">{{data.item.caption}}</div>
                                        <div class="px-2" style="white-space:break-spaces" v-else-if="data.item.body && notBase64(data.item.body)">{{data.item.body}}</div>
                                        <Msgdt :item="data.item"/>
                                    </div>
                                </div>
                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" v-else-if="data.item.type=='ptt' || data.item.type=='audio'">
                                    <div class="p-1 pb-4" v-bind:class="{ 'msgreceived': !data.item.fromMe,'msgsent': data.item.fromMe,}">
                                        <MsgOptions :mediaURL="mediaURL" :msg="data.item" :client="contact ? contact : null" @setReplyMessageEvent="setReplyMessage" v-if="inMessageSupportedTypes(data.item.type)" />
                                        <Msgfromgroup :msg="data.item"/>
                                        <Msgforwarded :msg="data.item" />
                                        <!-- <div class="receivedmediaaudiodiv pb-3 d-flex align-items-center rounded">
                                            <b-icon scale="1.75" icon="play-fill" @click="playAudio(data.item.url)"></b-icon>
                                            <div class="receivedmediarangediv">
                                                <b-form-input type="range"></b-form-input>
                                                <div class="receivedmediarangetime receivedmediarangetimeleft">0:00</div>
                                                <div class="receivedmediarangetime receivedmediarangetimetotal">5:00</div>
                                                <b-icon icon="mic-fill"></b-icon>
                                            </div>
                                            <b-icon scale="1.5" icon="headphones"></b-icon>
                                            
                                        </div> -->
                                        <video class="rounded audioplayer" v-bind:class="{'apreceived':!data.item.fromMe ,'apsent':data.item.fromMe}" controls :src="hasFullPath(data.item.mediaURL)" style="height:3em; width:18em" v-if="!pdfPrint"></video>
                                        <a class="px-1" :href="hasFullPath(data.item.mediaURL)" v-else>
                                            <!-- <b-icon class="me-2" icon="download"></b-icon> -->
                                            Áudio
                                        </a>
                                        <!-- <div class="text-secondary position-absolute" style="font-size: 0.7em; bottom: 2px; left:5px" v-if="!pdfPrint && browser != 'Firefox'">Caso não toque, utilize o Firefox!</div> -->
                                        <Msgdt :item="data.item" />
                                    </div>
                                </div>
                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" v-else-if="data.item.type=='location' ">
                                    <div class="p-1 pb-4" v-bind:class="{ 'msgreceived': !data.item.fromMe,'msgsent': data.item.fromMe }" style="width:50%">
                                        <Msgfromgroup :msg="data.item"/>
                                        <Msgforwarded :msg="data.item" />
                                        <Map class="m-0" :loc="data.item"></Map>
                                        <Msgdt :item="data.item" />
                                    </div>
                                </div>
                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" v-else-if="data.item.type=='vcard'">
                                    <div class="p-1 pe-5" v-bind:class="{ 'msgreceived': !data.item.fromMe,'msgsent': data.item.fromMe}">
                                        <Msgfromgroup :msg="data.item"/>
                                        <Msgforwarded :msg="data.item" />
                                        <div class="d-flex align-items-center">
                                            <b-avatar class="me-2"></b-avatar>
                                            <span class="d-flex flex-column">
                                                <div class="font-weight-bold">{{data.item.vcardName}}</div>
                                                <div>{{data.item.vcardNumber}}</div>
                                            </span>
                                        </div>
                                        <!-- <div class="d-flex align-items-center justify-content-center border-top text-primary" @click="appendToAttendances(data.item)" role="button">Enviar Mensagem</div> -->
                                        <Msgdt :item="data.item" />
                                    </div>
                                </div>
                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" v-else-if="data.item.type=='vcardArray'">
                                    <div class="p-2 pe-5" v-bind:class="{ 'msgreceived': !data.item?.fromMe,'msgsent': data.item?.fromMe }">
                                        <Msgfromgroup :msg="data.item"/>
                                        <Msgforwarded :msg="data.item" />
                                        <div class="d-flex flex-column gap-1">
                                            <div class="d-flex align-items-center" v-for="vcard in data.item.contacts" :key="vcard.vcardNumber">
                                                <b-avatar class="bg-secondary text-white me-2"></b-avatar>
                                                <span class="d-flex flex-column">
                                                    <div class="fw-bold">{{vcard.vcardName}}</div>
                                                    <div>{{vcard.vcardNumber}}</div>
                                                </span>
                                            </div>
                                        </div>
                                        <!-- <div class="d-flex align-items-center justify-content-center border-top text-primary" @click="appendToAttendances(data.item)" role="button">Enviar Mensagem</div> -->
                                        <Msgdt :item="data.item" />
                                    </div>
                                </div>
                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" v-else-if="data.item.type=='document' || data.item.type=='application' || data.item.type=='file'">
                                    <div class="p-1 pb-4" v-bind:class="{ 'msgreceived': !data.item.fromMe,'msgsent': data.item.fromMe}">
                                        <Msgfromgroup :msg="data.item"/>
                                        <Msgforwarded :msg="data.item" />
                                        <!-- {{data.item}} -->
                                        <div role="button" @click="downloadMedia(hasFullPath(data.item.mediaURL),data.item.ext)" v-if="!pdfPrint">
                                            <div class="downloadmediadiv rounded text-decoration-none">
                                                <div v-if="data.item.filename" class="text-white">{{data.item.filename}}</div>
                                                <b-icon 
                                                    :icon="(fileExt(data.item.caption)=='csv' || fileExt(data.item.caption)=='xlsx') ? 'file-earmark-spreadsheet-fill' : 'file-earmark-fill'" 
                                                    :class="{ 'text-success': (fileExt(data.item.caption)=='csv' || fileExt(data.item.caption)=='xlsx'), 'text-danger': fileExt(data.item.caption)=='pdf'}" 
                                                    font-scale="4"
                                                ></b-icon>
                                                <div>Baixar</div>
                                                <div>Documento</div>
                                            </div>
                                        </div>
                                        <a class="px-1" :href="hasFullPath(data.item.mediaURL)" v-else>
                                            <!-- <b-icon class="me-2" icon="download"></b-icon> -->
                                            Documento
                                        </a>
                                        <div v-if="data.item.caption">{{data.item.caption}}</div>
                                        <div style="position: absolute; left: 5px; bottom: 2px; font-size: 0.7em; color: #555; text-transform: uppercase;">{{fileExt(data.item.caption)}}</div>
                                        <Msgdt :item="data.item" />
                                    </div>
                                </div>
                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" v-else-if="data.item.type=='sticker'">
                                    <div  class="p-1 pe-5" v-bind:class="{ 'msgreceived': !data.item.fromMe,'msgsent': data.item.fromMe }">
                                        <Msgfromgroup :msg="data.item"/>
                                        <Msgforwarded :msg="data.item" />
                                        <img :src="hasFullPath(data.item.mediaURL)" v-if="!pdfPrint && data.item.mediaURL" style="width:100%"> 
                                        <a class="px-1" :href="hasFullPath(data.item.mediaURL)" v-else>
                                            <!-- <b-icon class="me-2" icon="download"></b-icon> -->
                                            Sticker
                                        </a>
                                        <!-- Sticker (Não Suportado) -->
                                        <Msgdt :item="data.item" />
                                    </div>
                                </div>
                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" class="p-1 pb-1" v-bind:class="{ 'msgreceived': !data.item.fromMe,'msgsent': data.item.fromMe }" v-else-if="data.item.type=='list-reply' || data.item.type=='interactive'">
                                    <span v-if="data.item.body?.interactive?.type == 'list'">
                                        <div class="p-2 pb-3 position-relative">
                                            <Msgfromgroup :msg="data.item"/>
                                            <Msgforwarded :msg="data.item" />
                                            <div class="fw-bold mb-1" style="white-space:break-spaces" v-if="data.item.body.interactive?.header?.text">{{ data.item.body.interactive.header.text }}</div> 
                                            <div style="white-space:break-spaces" v-if="data.item.body.interactive?.body?.text">{{ data.item.body.interactive.body.text }}</div> 
                                            <div class="smaller-text text-secondary" style="white-space:break-spaces" v-if="data.item.body.interactive?.footer?.text">{{ data.item.body.interactive.footer.text }}</div> 
                                            <Msgdt :item="data.item" />
                                        </div>
                                        <div class="border-top d-flex align-items-center justify-content-center text-primary p-1">
                                            <b-icon icon="list" class="me-1"></b-icon>
                                            {{ data.item.body.interactive.action.button }}
                                        </div>
                                    </span>
                                    <span v-else-if="data.item.body?.interactive?.type == 'button'">
                                        <div class="p-2 pb-3 position-relative">
                                            <Msgfromgroup :msg="data.item"/>
                                            <Msgforwarded :msg="data.item" />
                                            <div class="fw-bold mb-1" style="white-space:break-spaces" v-if="data.item.body.interactive?.header?.type == 'text' && data.item.body.interactive?.header?.text">{{ data.item.body.interactive.header.text }}</div> 
                                            <div class="mb-1" v-else-if="data.item.body.interactive?.header?.type == 'image' && data.item.body.interactive?.header?.image?.link">
                                                <img class="rounded" v-bind:src="hasFullPath(data.item.body.interactive.header.image.link)" style="max-width:100%; max-height: 5em;" @click="selectMedia(hasFullPath(data.item.body.interactive.header.image.link))" v-b-modal.modal-receivedmedia/>
                                            </div> 
                                            <div style="white-space:break-spaces" v-if="data.item.body.interactive?.body?.text">{{ data.item.body.interactive.body.text }}</div> 
                                            <div class="smaller-text text-secondary" style="white-space:break-spaces" v-if="data.item.body.interactive?.footer?.text">{{ data.item.body.interactive.footer.text }}</div> 
                                            <Msgdt :item="data.item" />
                                        </div>
                                        <div class="border-top border-2 d-flex align-items-center justify-content-center text-primary p-1" v-for="button in data.item.body.interactive.action.buttons" :key="button._id">
                                            {{ button.reply.title }}
                                        </div>
                                    </span>
                                </div>
                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" class="p-1 pb-1 pe-5" v-bind:class="{ 'msgreceived': !data.item.fromMe,'msgsent': data.item.fromMe }" v-else-if="data.item.type=='button_reply'">
                                    <Msgfromgroup :msg="data.item"/>
                                    <Msgforwarded :msg="data.item" />
                                    <span style="white-space:break-spaces">{{ data.item.body.button_reply?.title || data.item.body.title }}</span> 
                                    <Msgdt :item="data.item" />
                                    <!-- <div class="messagecornerreceived"></div> -->
                                </div>
                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" class="p-1 pb-1 pe-5" v-bind:class="{ 'msgreceived': !data.item.fromMe,'msgsent': data.item.fromMe }" v-else-if="data.item.type=='list_reply'">
                                    <Msgfromgroup :msg="data.item"/>
                                    <Msgforwarded :msg="data.item" />
                                    <span style="white-space:break-spaces">{{ data.item.body.list_reply?.title || data.item.body.title }}</span> 
                                    <Msgdt :item="data.item" />
                                </div>
                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" class="p-0 w-50" v-bind:class="{ 'msgreceived': !data.item.fromMe,'msgsent': data.item.fromMe }" v-else-if="data.item.type=='listPresentation'">
                                    <div class="p-2">
                                        <Msgfromgroup :msg="data.item"/>
                                        <Msgforwarded :msg="data.item" />
                                        <span class="" style="white-space:break-spaces">{{data.item.body}}</span> 
                                    </div>
                                    <div class="border-top d-flex align-items-center justify-content-center text-primary p-1">
                                        <b-icon icon="list"></b-icon>
                                        {{data.item.button}}
                                    </div>
                                </div>
                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" class="d-flex flex-column align-items-center justify-content-center w-100" v-else-if="data.item.type=='listSections'">
                                    <div class="w-100 text-white mh-50">
                                        <div class="bg-secondary d-flex align-items-center w-100 px-3 py-2 rounded-top">
                                            <b-icon icon="x" class="me-3" font-scale="1.5"></b-icon>
                                            <div class="fw-bold">{{data.item.button}}</div>
                                        </div>
                                        <div class="bg-dark overflow-y-auto px-3 py-2 rounded-bottom" style="max-height: 50vh">
                                            <div class="py-2" v-for="section in data.item.sections" :key="section._id">
                                                <div class="text-success">{{section.title}}</div>
                                                <div class="d-flex justify-content-between py-2" v-for="row in section.rows" :key="row._id">
                                                    <div class="d-flex flex-column justify-content-center">
                                                        <div>{{ row.title }}</div>
                                                        <div class="text-secondary small-text">{{ row.description }}</div>
                                                    </div>
                                                    <div class="d-flex align-items-center">
                                                        <b-form-radio disabled></b-form-radio>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" class="p-0" v-bind:class="{ 'msgreceived': !data.item?.fromMe,'msgsent': data.item?.fromMe }" style="min-width: 12em; max-width: 20em;" v-else-if="data.item.type=='product_list'">
                                    <Msgfromgroup :msg="data.item"/>
                                    <Msgforwarded :msg="data.item" />
                                    <b-row class="m-0 p-1">
                                        <b-col class="p-0" cols="4">
                                            <b-img class="w-100 rounded-start" :src="data.item.body.imageURL" v-if="data.item.body.imageURL"></b-img>
                                            <b-skeleton-img class="rounded-start pe-none" no-aspect animation="" v-else></b-skeleton-img>
                                        </b-col>
                                        <b-col class="d-flex flex-column justify-content-center p-2 bg-secondary bg-opacity-10 rounded-end">
                                            <div class="fw-semibold">
                                                {{ data.item.body.header.text }}
                                            </div>
                                            <div class="smaller-text text-secondary">
                                                {{ data.item.body.productList.length }} {{ 'item' | pluralize(data.item.body.productList?.length) }}
                                            </div>
                                        </b-col>
                                    </b-row>
                                    <div class="p-1">
                                        {{ data.item.body.body.text }}
                                    </div>
                                    <div class="border-top border-2 d-flex align-items-center justify-content-center text-primary p-1 fw-semibold">
                                        Ver Itens
                                    </div>
                                    <Msgdt :item="data.item" />
                                </div>
                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" class="p-0" v-bind:class="{ 'msgreceived': !data.item?.fromMe,'msgsent': data.item?.fromMe }" style="min-width: 12em; max-width: 20em;" v-else-if="data.item.type=='order'">
                                    <MsgOptions :mediaURL="mediaURL" :msg="data.item" :client="contact || null" @setReplyMessageEvent="setReplyMessage" />
                                    <Msgfromgroup :msg="data.item"/>
                                    <Msgforwarded :msg="data.item" />
                                    <b-row class="m-0 p-1" role="button" v-b-modal.viewProductsCart @click="selectProductCart(data.item.body)">
                                        <b-col class="p-0" cols="4">
                                            <b-img class="w-100 rounded-start" :src="data.item.body[0].image" v-if="data.item.body[0]?.image"></b-img>
                                            <b-skeleton-img class="rounded-start pe-none" animation="" no-aspect v-else></b-skeleton-img>
                                        </b-col>
                                        <b-col class="d-flex flex-column justify-content-center p-2 bg-secondary bg-opacity-10 rounded-end">
                                            <div class="fw-semibold hstack gap-1">
                                                <b-icon icon="cart-fill"></b-icon>
                                                <div>
                                                    Itens: {{ data.item.body.length }}
                                                </div>
                                            </div>
                                            <div class="smaller-text text-secondary" v-if="data.item.body?.length">
                                                Subtotal: R$ {{ String(data.item.body.reduce((acc, product) => acc + product.amount, 0)) | floatingPoint }}
                                            </div>
                                        </b-col>
                                    </b-row>
                                    <div class="border-top border-1 d-flex align-items-center justify-content-center text-primary p-1 fw-semibold">
                                        <span class="darkenTextOnHover" role="button" v-b-modal.viewProductsCart @click="selectProductCart(data.item.body)">
                                            Ver carrinho enviado
                                        </span>
                                    </div>
                                    <Msgdt :item="data.item" />
                                </div>
                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" v-bind:class="{ 'msgreceived': !data.item.fromMe,'msgsent': data.item.fromMe, 'bg-secondary': data.item.highlight, 'p-1': data.item.mediaURL }" v-else-if="data.item.type=='text' || data.item.type=='chat'">
                                    <MsgOptions :mediaURL="mediaURL" :msg="data.item" :client="contact ? contact : null" @setReplyMessageEvent="setReplyMessage" v-if="(user && user.channel?.apiType != 'cloud') && inMessageSupportedTypes(data.item.type)" />
                                    <MsgReplied :mediaURL="mediaURL" :msg="data.item"  :client="contact ? contact : null" v-if="data.item.quotedMsg" />
                                    <Msgfromgroup :msg="data.item"/>
                                    <Msgforwarded :msg="data.item" />
                                    <MsgCtwa :ctwaContext="data.item.ctwaContext" />
                                    <img class="rounded" v-bind:src="hasFullPath(data.item.mediaURL)" style="max-width:100%; height: auto;" @click="downloadMedia(hasFullPath(data.item.mediaURL))" v-b-modal.modal-receivedmedia v-if="!pdfPrint && data.item.mediaURL"/>
                                    <a class="px-1" :href="hasFullPath(data.item.mediaURL)" v-if="pdfPrint && data.item.mediaURL">
                                        <!-- <b-icon class="me-2" icon="download"></b-icon> -->
                                        Imagem
                                    </a>
                                    <div class="px-2" style="white-space:break-spaces" v-if="data.item.caption">{{data.item.caption}}</div>
                                    <span style="white-space:break-spaces">{{ data.item.body }}</span> 
                                    <Msgdt :item="data.item" />
                                    <!-- <div class="messagecornerreceived"></div> -->
                                </div>
                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" v-bind:class="{ 'msgreceived': !data.item.fromMe,'msgsent': data.item.fromMe, 'bg-secondary': data.item.highlight, 'p-1': data.item.mediaURL }" v-else-if="data.item.type=='quick_reply'">
                                    <MsgOptions :mediaURL="mediaURL" :msg="data.item" :client="contact ? contact : null" @setReplyMessageEvent="setReplyMessage" v-if="(user && user.channel?.apiType != 'cloud') && inMessageSupportedTypes(data.item.type)" />
                                    <MsgReplied :mediaURL="mediaURL" :msg="data.item"  :client="contact ? contact : null" v-if="data.item.quotedMsg" />
                                    <Msgfromgroup :msg="data.item"/>
                                    <Msgforwarded :msg="data.item" />
                                    <span style="white-space:break-spaces">{{ data.item.body.text }}</span> 
                                    <Msgdt :item="data.item" />
                                    <!-- <div class="messagecornerreceived"></div> -->
                                </div>
                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" class="p-0" v-bind:class="{ 'msgreceived': !data.item.fromMe,'msgsent': data.item.fromMe }" v-else-if="data.item.type == 'template'">
                                    <Msgfromgroup :msg="data.item"/>
                                    <Msgforwarded :msg="data.item" />
                                    <span class="p-2 pb-3">
                                        <div class="templateHeader mb-1">
                                            <span class="fw-bold" v-if="getTemplateHeader(data.item.body.components || data.item.body.template?.components)?.format === 'TEXT' && getTemplateHeader(data.item.body.components || data.item.body.template?.components).text">{{ getTemplateHeader(data.item.body.components || data.item.body.template?.components).text  }}</span>
                                            <b-img class="w-100 rounded" v-else-if="!pdfPrint && (getTemplateHeader(data.item.body.components || data.item.body.template?.components)?.format === 'IMAGE' && getTemplateHeader(data.item.body.components || data.item.body.template?.components)?.example) || (data.item.body.templateType === 'IMAGE' && data.item.body.url)" :src="getTemplateHeader(data.item.body.components || data.item.body.template?.components)?.example?.header_handle[0] || data.item.body.url" />
                                            <div class="bg-999 vstack align-items-center rounded p-2" v-else-if="!pdfPrint && data.item.body.templateType === 'IMAGE' && !data.item.body.url">
                                                <b-icon icon="image" font-scale="3"></b-icon>
                                                <div>
                                                    Imagem
                                                </div>
                                            </div>
                                            <a class="px-1" :href="getTemplateHeader(data.item.body.components || data.item.body.template?.components).example.header_handle[0]" v-else-if="pdfPrint && getTemplateHeader(data.item.body.components || data.item.body.template?.components)?.format === 'IMAGE' && getTemplateHeader(data.item.body.components || data.item.body.template?.components).example">
                                                <!-- <b-icon class="me-2" icon="download"></b-icon> -->
                                                Imagem
                                            </a>
                                            <video controls v-else-if="!pdfPrint && getTemplateHeader(data.item.body.components || data.item.body.template?.components)?.format === 'VIDEO' && getTemplateHeader(data.item.body.components || data.item.body.template?.components)?.example || (data.item.body.templateType === 'VIDEO' && data.item.body.url)" :src="getTemplateHeader(data.item.body.components || data.item.body.template?.components)?.example?.header_handle[0] || data.item.body.url"></video>
                                            <div class="bg-999 vstack align-items-center rounded p-2" v-else-if="!pdfPrint && data.item.body.templateType === 'VIDEO' && !data.item.body.url">
                                                <b-icon icon="play-fill" font-scale="3"></b-icon>
                                                <div>
                                                    Vídeo
                                                </div>
                                            </div>
                                            <a class="px-1" :href="getTemplateHeader(data.item.body.components || data.item.body.template?.components).example.header_handle[0]" v-else-if="pdfPrint && getTemplateHeader(data.item.body.components || data.item.body.template?.components)?.format === 'VIDEO' && getTemplateHeader(data.item.body.components || data.item.body.template?.components).example">
                                                <!-- <b-icon class="me-2" icon="download"></b-icon> -->
                                                Vídeo
                                            </a>
                                            <video controls v-else-if="!pdfPrint && getTemplateHeader(data.item.body.components || data.item.body.template?.components)?.format === 'AUDIO' && getTemplateHeader(data.item.body.components || data.item.body.template?.components)?.example || (data.item.body.templateType === 'AUDIO' && data.item.body.url)" :src="getTemplateHeader(data.item.body.components || data.item.body.template?.components)?.example?.header_handle[0] || data.item.body.url"></video>
                                            <div class="bg-999 vstack align-items-center rounded p-2" v-else-if="!pdfPrint && data.item.body.templateType === 'AUDIO' && !data.item.body.url">
                                                <b-icon icon="play-fill" font-scale="3"></b-icon>
                                                <div>
                                                    Áudio
                                                </div>
                                            </div>
                                            <a class="px-1" :href="getTemplateHeader(data.item.body.components || data.item.body.template?.components).example.header_handle[0]" v-else-if="pdfPrint && getTemplateHeader(data.item.body.components || data.item.body.template?.components)?.format === 'VIDEO' && getTemplateHeader(data.item.body.components || data.item.body.template?.components).example">
                                                <!-- <b-icon class="me-2" icon="download"></b-icon> -->
                                                Áudio
                                            </a>
                                            <span v-else-if="getTemplateHeader(data.item.body.components || data.item.body.template?.components)?.format === 'DOCUMENT' && getTemplateHeader(data.item.body.components || data.item.body.template?.components).example || (data.item.body.templateType === 'DOCUMENT' && data.item.body.url)">
                                                <div role="button" @click="downloadMedia(getTemplateHeader(data.item.body.components || data.item.body.template?.components).example.header_handle[0])">
                                                    <div class="downloadmediadiv rounded text-decoration-none p-3">
                                                        <b-icon icon="file-earmark-fill" font-scale="4"></b-icon>
                                                        <!-- <div>Baixar</div>
                                                        <div>Documento</div> -->
                                                    </div>
                                                </div>
                                            </span>
                                            <span v-else-if="data.item.body.templateType === 'DOCUMENT' && !data.item.body.url">
                                                <div>
                                                    <div class="downloadmediadiv rounded text-decoration-none p-3">
                                                        <b-icon icon="file-earmark-fill" font-scale="4"></b-icon>
                                                        <div>Documento</div>
                                                    </div>
                                                </div>
                                            </span>
                                            <div class="vstack gap-1" v-else-if="data.item.body?.mediaId">
                                                <div class="text-secondary smaller-text" v-if="data.item.body?.name">
                                                    Template HSM: {{ data.item.body?.name }}
                                                </div>
                                                <div class="p-5 w-100 d-flex align-items-center justify-content-center bg-secondary rounded text-white">
                                                    <b-icon font-scale="2.5" icon="image" />
                                                </div>
                                            </div>
                                        </div>
                                        <span class="templateBody" style="white-space:break-spaces">{{ getTemplateBody(data.item.body.components || data.item.body.template?.components) || data.item.body.body || data.item.body.data }}</span>
                                        <div class="templateFooter small-text text-secondary">{{ getTemplateFooter(data.item.body.components || data.item.body.template?.components) || data.item.body.footer }}</div>
                                    </span>
                                    <span class="templateButton" v-if="getTemplateButton(data.item.body.components || data.item.body.template?.components) || data.item.body.buttons?.length">
                                        <div class="border-top border-2 d-flex align-items-center justify-content-center text-primary p-1" v-for="button in getTemplateButton(data.item.body.components || data.item.body.template?.components) || data.item.body.buttons" :key="button._id" @click="openLink(button.value || button.url)">
                                            {{ button.text }}
                                        </div>
                                    </span>
                                    <Msgdt :item="data.item" />
                                    <!-- <div class="messagecornerreceived"></div> -->
                                </div>
                            </span>
                            <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" class="position-absolute bg-grey rounded-pill px-2 mx-3 border border-1 border-secondary" style="bottom: -0em; z-index: 1" v-if="data.item.react?.length" role="button" @click="toggleReactInfo(data.item)">
                                <span v-for="emoji in data.item.react" :key="emoji._id">
                                    <span>{{ emoji.reaction }}</span>
                                </span>
                                <span class="ms-2" v-if="data.item.react.length > 1">{{ data.item.react.length }}</span>
                            </div>
                            <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" class="position-absolute bottom-0 mx-2 bg-secondary rounded shadow-sm" style="z-index: 2" v-if="data.item.showReactInfo">
                                <div v-for="item in data.item.react" :key="item._id">
                                    <div class="d-flex align-items-center justify-content-between px-2 py-1">
                                        <div>
                                            {{ item.name || item.number || "----" }}
                                        </div>
                                        <span class="ms-2">{{ item.reaction }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="w-100 h-100 position-fixed top-0 end-0 start-0 bottom-0" role="window" style="z-index: 1000" @click="toggleReactInfo(data.item)" v-if="data.item.showReactInfo">
                            </div>
                        </div>
                    </template>
                </b-table>
            </div>
        </div>
        <b-modal id="viewProductsCart" ref="viewProductsCart" title="Carrinho" hide-footer header-class="py-0" v-if="selectedProductCart">
            <div class="d-flex flex-column align-items-center gap-2">
                <span>
                    <div class="productFromCart" v-for="product in selectedProductCart" :key="product._id">
                        <b-row class="m-0 gap-2 border-bottom">
                            <b-col class="p-0 d-flex align-items-center" cols="4">
                                <b-img class="w-100" :src="product.image" v-if="product.image"></b-img>
                                <b-skeleton-img class="pe-none" no-aspect animation="" v-else></b-skeleton-img>
                            </b-col>
                            <b-col class="p-2 d-flex flex-column justify-content-center">
                                <div class="fs-5 mb-1 fw-semibold">
                                    {{ product.title }}
                                </div>
                                <div class="text-secondary box-clamp-1">
                                    {{ product.description }}
                                </div>
                                <div class="text-purple">
                                    Quantidade: {{ product.quantity }}
                                </div>
                                <div class="text-purple">
                                    <span class="fs-4 fw-bold">
                                        R$ {{ String(product.amount) | floatingPoint }}
                                    </span>
                                    <!-- <span class="smaller-text">
                                        com pix
                                    </span> -->
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </span>
                <b-button variant="success" @click="$bvModal.hide('viewProductsCart')">
                    Fechar
                </b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Map from './map.vue'
import Msgdt from './msgdt.vue'
import Msgforwarded from './msgforwarded.vue'
import Msgfromgroup from './msgfromgroup.vue'
import MsgReplied from './msgReplied.vue'
import MsgOptions from './msgOptions.vue'
import axios from 'axios'
import { VEmojiPicker } from 'v-emoji-picker'
import api from '../services/apiService'
import utils from '../utils/utils'
import MsgCtwa from './msgCtwa.vue'

export default {
    components: {
        Map, Msgdt, Msgforwarded, Msgfromgroup, MsgReplied, MsgOptions,
        MsgCtwa,
        VEmojiPicker
    },
    props: [
        'messages', 'height', 'contact', 'user' ,'i18n', 'timestampDiff', 'pdfPrint', 'mediaURL',
        'showLoadMessagesButton',
        'showLoadHistoryButton',
        'showLoadMoreInternalMessagesButton',
        'loading'
    ],
    mounted() {
        const waBg = document.querySelector('.waBg')
        this.scrollFunction()
        waBg.onscroll = () => {this.scrollFunction()};
        this.setScrollButton()
        this.setBrowser()
        this.rootEvents()

        if (this.messages?.length)
            this.manipulateMessages()
    },
    watch: {
        'messages': {
            handler () {
                this.manipulateMessages()
            }
        },
        'msgSearchTotal': {
            handler () {
                this.$root.$emit('msgSearchTotal',this.msgSearchTotal)
            }
        },
        'msgSearchActual': {
            handler () {
                this.$root.$emit('msgSearchActual',this.msgSearchActual)
            }
        }
    },
    methods:{
        scrollFunction() {
            const button = document.querySelector(".msgsScrollButton");
            const waBg = document.querySelector('.waBg');
            const height = waBg.scrollHeight / 2;
            
            if (button && waBg.scrollTop < (1.5 * height)) {
                button.style.display = "flex";
            } else if (button) {
                button.style.display = "none";
            }
        },
        setScrollButton() {
            const waBg = document.querySelector('.waBg');
            const whatsappMsgs = document.querySelector('.whatsappMsgs');

            const elementToScroll = waBg || whatsappMsgs;
            if (elementToScroll) {
                elementToScroll.scrollTo(0, elementToScroll.scrollHeight);
            }
        },
        getDate(t,args) {
            if (args) {
                return new Date(t*1000).toLocaleDateString("pt-BR").split('/').reverse().join('-')
            }
            return new Date(t).toLocaleDateString("pt-BR").split('/').reverse().join('-')
        },
        hasFullPath(url) {
            if (url && (url.startsWith('http') || url.startsWith('data:'))) return url
            else if (url) {
                let split = url.split('/')
                if (split?.at(2)?.length == 3) // verify if the position 2 (usually it is the channel id) has length of 3, then it means the media comes from Notifique
                    return "https://storage.notifiqueai.com.br/"+url
            }
            return this.mediaURL+url
        },
        fileExt(filename) {
            if (filename && filename.split('').reverse().join('')) {
                let ext = filename.split('').reverse().join('').split('.')[0].split('').reverse().join('');
                return ext
            }
            return ''
        },
        notBase64(string) {
            // if (string[0] == '/' && string[string.length-1] == '=') {
            if (typeof string == 'string') {
                if (string.startsWith('/9'))
                    return false
                return true
            } else {
                console.log(typeof string, string)
            }
        },
        downloadMedia(url, ext = null) {
            url = utils.replaceDiacritics(decodeURIComponent(url))
            let filename = url.replace('download=false', '').split('/').pop().split('=').at(-1)

            if (!ext)
                ext = url.split('').reverse().join('').split('.')[0].split('').reverse().join('')
            
            filename = filename.replace(`.${ext}`, '')

            const link = document.createElement('a');
            if (url.includes('download=false')) {
                link.href = url.replace('download=false', '');
                link.setAttribute('download','');
                link.setAttribute('target', '_blank');
                link.click();
            } else {
                axios({
                    url,
                    method: 'GET',
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    link.href = url;
                    link.setAttribute('download',`${filename}.${ext}`);
                    link.setAttribute('target', '_blank');
                    link.click();
                }).catch(() => {
                    link.href = url;
                    link.setAttribute('download',`${filename}.${ext}`);
                    link.setAttribute('target', '_blank');
                    link.click();
                })
            }
        },
        getTemplateHeader(components) {
            if (!components) return
            const header = components.find(({ type }) => type === "HEADER")
            return header
        },
        getTemplateBody(components) {
            return components?.find(el => el.type === "BODY")?.text
        },
        getTemplateFooter(components) {
            return components?.find(el => el.type === "FOOTER")?.text
        },
        getTemplateButton(components) {
            if (components) {
                const buttons = components.find(el => el.type?.toLowerCase() == "buttons" || el.buttons?.length)
                const button = components.filter(el => el.type?.toLowerCase() == "button")
                if (buttons)
                    return buttons.buttons
                else if (button)
                    return button
            }
        },
        verifyPhotoURL(url) {
            if (url) {
                if (!url.includes('http'))
                    return `${this.mediaURL}${url}`
                return url
            }
        },
        async manipulateMessages() {
            let msgs = []
            let dateAux = new Date(1)
            const year = dateAux.getFullYear();
            const month = (dateAux.getMonth() + 1).toString().padStart(2, '0')
            const day = dateAux.getDate().toString().padStart(2, '0')
            dateAux = `${month}-${day}-${year}`
            for (const el of this.messages) {
                if (!el.timestamp) {
                    if (el.createdAt) {
                        el.timestamp = Math.floor(new Date(el.createdAt).getTime() / 1000)
                    } else {
                        const aux = new Date()
    
                        el.timestamp = Math.floor(aux.getTime() / 1000) + Math.floor(aux.getTimezoneOffset() ? aux.getTimezoneOffset() / 12 : 0)
                    }
                }

                if (this.timestampDiff > 100)
                    el.timestamp -= this.timestampDiff
                
                if (el.timestamp > 9999999999)
                    el.timestamp /= 1000

                let date = new Date(el.timestamp*1000)
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0')
                const day = date.getDate().toString().padStart(2, '0')
                date = `${month}-${day}-${year}`
                if (new Date(date) > new Date(dateAux)) {
                    const d = { type: 'date', timestamp: el.timestamp-1, date }
                    msgs.push(d)
                    dateAux = date
                }

                if (el.type === "vcard") {
                    try {
                        if (!el.vcardName)
                            el.vcardName = el.body.split('FN:')[1].split('\n')[0]
                        if (!el.vcardNumber)
                            el.vcardNumber = el.body.split('waid=')[1].split(':')[0]
                    } catch(error) {
                        console.error('vcard err',error)
                    }
                } else if (el.type === 'vcardArray') {
                    el.contacts = []
                    for (const index in el.body) {
                        const obj = {
                            vcardName: el.body[index].split('FN:')[1].split('\n')[0],
                            vcardNumber: el.body[index].split('waid=')[1].split(':')[0]
                        }
                        el.contacts[index] = obj
                    }
                } else if (el.type === 'product_list') {
                    try {
                        const product = await this.getProductByProductId(el.body?.productList?.at(0)?.productId)
                        if (product) {
                            el.body.imageURL = product.imageLink
                        }
                    } catch(error) {
                        console.error(error)
                    }
                }

                if (el.mediaURL) {
                    try {
                        el.mediaURL = await this.getMediaURL(this.hasFullPath(el.mediaURL))
                    } catch(error) {
                        console.error(error)
                    }
                }

                msgs.push(el)
            }
            this.msgs = msgs
        },
        async getMediaURL(url) {
            if (url) {
                if (url.includes('medias2/dev'))
                    url = url.replace('medias2/dev', 'medias2/api4')
                const f = await fetch(url).then(async (resp) => {
                    if (resp.status == 404 && this.mediaURL != "https://storage.gotalk.digital/") {
                        // Try the other URL
                        url = url.replace(this.mediaURL,"https://storage.gotalk.digital/");
                        const f = await fetch(url)
                        .then(() => {
                            return url
                        })
                        return f
                    }
                    return url
                }).catch(async () => {
                    if (this.mediaURL != "https://storage.gotalk.digital/") {
                        // Try the other URL
                        url = url.replace(this.mediaURL,"https://storage.gotalk.digital/");
                        const f = await fetch(url)
                        .then(() => {
                            return url
                        })
                        return f
                    } else
                        return url
                });
                return f
            }
            return url
        },
        goToMessage(msg, include = false) {
            let idWpp

            if (msg.id?.id)
                idWpp = msg.id.id
            else
                idWpp = msg.id
                
            const msgs = this.msgs;
            if (msgs && msgs.length) {
                if (msg.type == 'chat' || msg.type == 'text') {
                    if (!idWpp) {
                        const found = include
                            ? msgs.find(el => el.body?.toLowerCase().includes(msg.body.toLowerCase()))
                            : msgs.find(el => el.body == msg.body);
                        if (found) {
                          idWpp = found.idWpp
                        } else {
                            let msg = {
                                text: "O item citado está em um protocolo anterior ao atendimento atual.",
                                success: false
                            }
                            return this.$emit("msg", msg)
                        }
                    }
                    const element = document.getElementById(idWpp) || document.getElementById(`d${parseInt(idWpp)}`);
                    if (element) {
                        element.scrollIntoView();
                    }
                } else {
                    const element = document.getElementById(idWpp) || document.getElementById(`d${parseInt(idWpp)}`);
                    if (element) {
                        element.scrollIntoView();
                    }
                }
            }
        },
        goToMessageSearch(direction = "up") {
            if (this.msgs?.length) {
                const found = this.msgs.filter(el => el.body?.toLowerCase().includes(this.inputSearchGroupMsg.toLowerCase()) && el.idWpp);
                if (found?.length) {
                    this.msgSearchTotal = found.length;
                    if (this.msgSearchActual) {
                        if (direction === "up" && this.msgSearchActual < this.msgSearchTotal) {
                            ++this.msgSearchActual;
                        } else if (direction !== "up" && this.msgSearchActual > 1) {
                            --this.msgSearchActual;
                        }
                    } else {
                        this.msgSearchActual = 1;
                    }
                    const el = found[found.length - this.msgSearchActual];
                    if (el) {
                        let id = document.getElementById(el.idWpp);
                        if (!id) {
                            id = document.getElementById(`d${parseInt(el.idWpp)}`);
                            if (id) {
                                id.scrollIntoView();
                            }
                        } else {
                            id.scrollIntoView();
                        }
                        setTimeout(() => {
                            el.highlight = true;
                            el.timestamp++;
                            el.timestamp--;
                        }, 100);
                        setTimeout(() => {
                            el.highlight = false;
                            el.timestamp++;
                            el.timestamp--;
                        }, 1000);
                    }
                }
            }
        },
        searchGroupMsg() {
            this.msgSearchActual = 0
            this.msgSearchTotal = 0
            this.goToMessageSearch()
        },
        inMessageSupportedTypes(type) {
            const supportedTypes = new Set([
                'image', 'video', 'ptt', 'audio', 'location', 'vcard', 'vcardArray', 'document', 'application',
                'sticker', 'list-reply', 'interactive', 'button_reply', 'text', 'chat', 'quick_reply', 'list_reply'
            ]);

            return supportedTypes.has(type);
        },
        toggleDialogReactEmoji(item) {
            this.$root.$emit('toggleDialogReactEmoji', item)
            const msgs = document.querySelector('.whatsappMsgs')
            this.msgsHeight = `${msgs.clientHeight}px`
            setTimeout(() => {
                const el = document.querySelector(".inputReactEmojiPicker")
                if (el)
                    el.scrollIntoView()
            }, 200);
        },
        selectReactEmoji(emoji) {
            this.$root.$emit('selectReactEmoji',emoji)
        },
        toggleReactInfo(item) {
            item.showReactInfo = !item.showReactInfo
            item.timestamp++
            item.timestamp--
        },
        setBrowser() {
            try {
                let ua = navigator.userAgent.split(' ')
                ua = ua[ua.length-1].split('/')[0]
                this.browser = ua
            } catch(error) {
                console.error('setBrowser',error)
            }
        },
        setReplyMessage(message) {
            this.$emit('setReplyMessageEvent',message)
        },
        async getProductByProductId(productId) {
            if (productId) {
                const resp = await api.getCatalogProductByProductId(this.user?.channelId || this.user?.roleId, productId)
                if (resp.result?.statusCode == 200)
                    return resp.result.product
            }
            return null
        },
        openLink(url) {
            if (url) {
                if (!url.startsWith("http"))
                    url = `https://${url}`
                window.open(url,'_blank');
            }
        },
        loadGroupPreviousMessages() {
            this.loadingPreviousGroupMessages = true
            this.$root.$emit('loadGroupPreviousMessages')
        },
        async selectProductCart(obj) {
            // if (!obj.productCart?.at(0)?.displayTitle) {
            //     const cart = []
            //     for (const el of obj.productCart) {
            //         const product = await this.getProductByProductId(el.productId) // bring info different than the one in obj
            //         cart.push(product)
            //     }
            //     obj.productCart = cart
            // }
                
            this.selectedProductCart = obj
        },
        async loadProtocols() {
            this.loadingProtocol = true
            this.$root.$emit('loadProtocolMessages')
        },
        async loadMoreInternalMessages() {
            this.loadingInternalMessages = true
            this.$emit('loadMoreInternalMessages')
        },
        formatDate(date) {
            return new Date(date).toLocaleString()
        },
        selectMedia(media) {
            this.$emit('selectedMedia', media)  
        },
        rootEvents() {
            this.$root.$on('scrollBottom', () => {
                this.setScrollButton()
            })

            this.$root.$on('scrollBottomRM', () => {
                this.setScrollButton()
            })
            
            this.$root.$on('scrollBottomSM', () => {
                this.setScrollButton()
            })

            this.$root.$on('goToMessage', (msg) => {
                this.goToMessage(msg,true)
            })

            this.$root.$on('searchGroupMsg', (inputSearchGroupMsg) => {
                this.inputSearchGroupMsg = inputSearchGroupMsg
                this.searchGroupMsg()
            })

            this.$root.$on('goToMessageSearch', (direction) => {
                this.goToMessageSearch(direction)
            })

            this.$root.$on('loadedGroupPreviousMessages', () => {
                this.loadingPreviousGroupMessages = false
            })

            this.$root.$on('loadedProtocolMessages', () => {
                this.loadingProtocol = false
            })

            this.$root.$on('loadedInternalMessages', () => {
                this.loadingInternalMessages = false
            })
        }
    },
    data() {
        return {
            msgs: [],
            msgSearchActual: 0,
            msgSearchTotal: 0,
            inputSearchGroupMsg: "",
            msgsHeight: document.querySelector('.whatsappMsgs')?.clientHeight,
            browser: '',
            loadingPreviousGroupMessages: false,
            selectedProductCart: null,
            loadingProtocol: false,
            loadingInternalMessages: false
        }
    }
}
</script>

<style>
    .tablemsgs tr{
        display: flex;
        width: 100%;
    }
    .tableW th{
        /* display: none !important; */
        width: 100%;
        padding: 0 !important;
    }
    .tablemsgs td{
        padding-top: 1 !important;
        overflow-wrap: break-word;
        word-break: break-word;
        box-shadow: none;
        width: 100%;
    }
    .tablemsgs{
        margin: 0;
        position: relative;
        height: inherit;
        overflow-y: scroll;
    }
    .msgdtday{
        background-color: #95a5a6;
        color: #fff;
        font-weight: 400;
        padding: 5px 10px;
        border-radius: 5px;
        border: none;
        text-align: center;
        display: flex;
        margin: 0 auto;
        width: fit-content;
    }
    .msgsent{
        background-color: hsl(94deg 78% 87%);
        float: right;
        clear: both;
        border-radius: 0.25rem;
        padding: 5px 10px;
        box-shadow: 2px 2px 5px #999;
        position: relative;
        padding-right: 3.25em;
        margin-left: 2em !important;
        margin-right: 0.5em !important;
        min-width: 8%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-break: auto;
    }
    .msgreceived{
        background-color: #fff;
        float: left;
        clear: both;
        border-radius: 0.25rem;
        box-shadow: -2px 2px 5px #999;
        padding: 5px 10px;
        position: relative;
        padding-right: 3em;
        margin-right: 2em !important;
        margin-left: 0.5em !important;
        min-width: 8%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-break: auto;
    }
    .pdfPrint .msgsent, .pdfPrint .msgreceived {
        border: 1px solid #ccc;
    }
</style>

<style scoped>
    .waBg {
        background-image: url('https://firebasestorage.googleapis.com/v0/b/gotalk-app.appspot.com/o/assets%2Fassets_whatsapp%5B1%5D.png?alt=media&token=6b8e699e-d1cf-4d2a-a3cd-ce5cba3cc205');
        overflow-y: auto;
    }
    .pdfPrint .waBg {
        background-image: none;
        background-color: hsl(0deg 0% 91%);
        border-radius: 0 !important;
    }
    .msgsScrollButton {
        bottom: 1.5em;
        right: 1.5em;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        z-index: 2;
        opacity: 0.75;
    }
    .msgsScrollButton:hover {
        opacity: 1;
    }
    .pdfPrint .msgsScrollButton {
        display: none;
    }
    #msgWrapper:hover .reactButton{
        display: flex !important;
    }
    .inputReactEmojiPicker{
        position: absolute;
        flex-direction: column-reverse !important;
        z-index: 1;
        /* bottom: 0; */
        top: 0;
        left: 1.5em;
    }
    .downloadmediadiv{
        background-color: #999;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.25em 2em;
        color: #222;
    }
    .downloadmediadiv:hover {
        color: #000;
    }
    .pdfPrint .msgImage {
        image-rendering: optimizeSpeed;
    }
</style>