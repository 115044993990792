<template>
    <div class="vstack" v-if="ctwaContext">
        <div class="hstack gap-1 smaller-text text-secondary">
            <b-icon icon="chat-right-text" />
            <span>Enviada via anúncio</span>
        </div>
       <a class="vstack" :href="ctwaContext.sourceUrl || '#'" target="_blank">
            <b-img 
                class="w-100 rounded-top object-fit-cover" 
                :src="imageSrc"
                style="max-height: 12em;"
                @error="onImageError"
                v-if="ctwaContext?.thumbnailUrl"
            />
            <div class="bg-light p-2 rounded-bottom" :class="{'rounded-top': !ctwaContext.thumbnailUrl}">
                <div class="fw-semibold">
                    {{ ctwaContext.title }}
                </div>
                <div class="small-text text-secondary box-clamp-1">
                    {{ ctwaContext.description || ctwaContext.body }}
                </div>
                <div class="smaller-text text-secondary">
                    {{ ctwaContext.sourceUrl }}
                </div>
            </div>
        </a>
    </div>
</template>

<script>

export default {
    props: [
        'ctwaContext'
    ],
    data() {
        return {
            imageSrc: this.ctwaContext?.thumbnailUrl,
            fallbackSrc: `data:image/jpeg;base64,${this.ctwaContext?.thumbnail}`
        }
    },
    methods: {
        onImageError() {
            this.imageSrc = this.fallbackSrc;
        }
    }
}
</script>